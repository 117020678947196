<template>
  <div ref="root" class="relative overflow-hidden">
    <div class="absolute inset-0">
      <img :src="thumbnailSrc" :class="innerClass" class="blur filter" />
    </div>
    <img
      ref="img"
      :srcset="isPreloading ? srcset : ''"
      :sizes="sizes"
      :class="[hasLoaded ? '' : 'opacity-0', innerClass]"
      class="relative z-[2] transition-opacity duration-300"
      @load="onLoad"
    />
  </div>
</template>

<script lang="ts" setup>
import type { ShopifyImageFragment } from '@/shopify-storefront'
import type { SrcsetSizes } from '@/components/payload/image/types'

interface Props {
  innerClass?: string
  image: ShopifyImageFragment
  lazy?: boolean
  sizes?: SrcsetSizes
}

const props = withDefaults(defineProps<Props>(), {
  innerClass: 'w-full h-full object-cover',
  lazy: false,
  sizes: () => ({
    default: '100vw'
  }),
})

const thumbnailSrc = computed(() => `${props.image.url}&width=50`)
const srcset = computed(() =>
  [320, 640, 960, 1200, 1600, 2000]
    .map((size) => `${props.image.url}&width=${size} ${size}w`)
    .join(', '),
)
const sizes = srcsetSizesToAttribute(props.sizes)

const root = ref<HTMLElement | null>(null)
const img = ref<HTMLImageElement | null>(null)
const emit = defineEmits(['load'])
const hasLoaded = ref(false)
const isPreloading = ref(!props.lazy)

function onLoad() {
  hasLoaded.value = true
  emit('load')
}

onMounted(() => {
  const { top } = useElementBounding(root)

  watchEffect(() => {
    if (props.lazy) {
      isPreloading.value = hasLoaded.value || top.value < window.innerHeight * 2
    }
  })

  if (img.value!.complete) {
    onLoad()
  }
})
</script>
